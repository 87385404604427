import "../../App.css";

import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faCalendar, faDownload } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select from "react-select";
import { getData } from "../../helpers/Data";
import moment from "moment";
import { saveAs } from "file-saver";

interface IProps {}

interface IState {
  filterItem: string;
  fromDate?: Date;
  statistics?: any;
  toDate?: Date;
}

const backgroundColor = [
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
];

export default class Dashboard extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterItem: "2",
      fromDate: moment().startOf("year").toDate(),
      toDate: moment().endOf("day").toDate(),
    };
  }

  static contextType = AuthContext;
  componentDidMount = () => {
    this.refresh();
  };

  refresh() {
    getData("reports/dashboard", {
      companyId: this.context.selectedCompany?.id,
      from: this.state.fromDate !== undefined ? moment(this.state.fromDate).format("DD MMM YYYY") : "",
      to: this.state.toDate !== undefined ? moment(this.state.toDate).format("DD MMM YYYY hh:mm") : "",
    }).then((data) => {
      console.log("DASH", data);
      this.setState({ statistics: data });
    });
  }

  setFilterItem = (items: any) => {
    let newFilterItem: string = "";

    items.forEach((element: any) => {
      newFilterItem = element.value;
    });

    let newFromDate = undefined;
    let newToDate = undefined;

    if (newFilterItem === "1") {
      newFromDate = moment().startOf("month").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "2") {
      newFromDate = moment().startOf("year").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "3") {
      newFromDate = moment().startOf("month").subtract(6, "months").toDate();
      newToDate = moment().endOf("day").toDate();
    }

    this.setState({ filterItem: newFilterItem, fromDate: newFromDate, toDate: newToDate }, () => this.refresh());
    // this.setState({ filterItem }, () => this.refresh())
  };

  saveChartToPng = (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    canvas.toBlob((blob: any) => {
      saveAs(blob, `${name}.png`);
    });
  };

  render() {
    if (this.state.statistics) {
      const catPercentageData = {
        labels: this.state.statistics.carPercentagePerCategories?.map((a: any, i: number) => a.categoryName),
        datasets: [
          {
            label: "%",
            data: this.state.statistics.carPercentagePerCategories?.map((a: any, i: number) => a.percentage),
            backgroundColor: backgroundColor,
            borderWidth: 0,
          },
        ],
      };

      let companyPrefix = this.context.selectedCompany.companyPrefix != null ? this.context.selectedCompany.companyPrefix : "CAR";

      const catPercentageOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
            text: companyPrefix + " % Per Category",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "bottom",
            borderRadius: 3,
            font: {
              size: 24,
            },
            color: "#fff",
            formatter: (value: any) => {
              return value + "%";
            },
          },
        },
      };

      const carAgePerDepartmentData = {
        labels: this.state.statistics.carAgePerDepartments?.map((a: any, i: number) => a.departmentName),
        datasets: [
          {
            label: "Average Age",
            data: this.state.statistics.carAgePerDepartments?.map((a: any, i: number) => a.age),
            backgroundColor: this.state.statistics.carAgePerDepartments?.map((a: any, i: number) => a.colour),
            borderWidth: 0,
          },
        ],
      };

      const carAgePerDepartmentOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Average " + companyPrefix + " Age Per Department",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
          },
        },
      };

      const costPerDepartmentsData = {
        labels: this.state.statistics.costPerDepartments?.map((a: any, i: number) => a.departmentName),
        datasets: [
          {
            label: "Cost",
            data: this.state.statistics.costPerDepartments?.map((a: any, i: number) => a.cost),
            backgroundColor: this.state.statistics.costPerDepartments?.map((a: any, i: number) => a.colour),
            borderWidth: 0,
          },
        ],
      };

      const costPerDepartmentsOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Cost Per Department",
            font: {
              size: 24,
            },
          },
          subtitle: {
            display: true,
            text: "Year-to-date",
            font: {
              size: 20,
            },
          },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
            formatter: (value: any) => {
              return value.toLocaleString("en-ZA", {
                style: "currency",
                currency: "ZAR",
              });
            },
          },
        },
      };

      const selectOptions = [
        { value: "1", label: "Month to Date" },
        { value: "2", label: "Year to Date" },
        { value: "3", label: "Custom" },
      ];

      let row;
      if (Number(this.state.filterItem) < 3) {
        row = (
          <Row className="align-items-center">
            <Col>Quality Dashboard</Col>
            <Col sm={3} md={3} lg={4}>
              <Select options={selectOptions} defaultValue={selectOptions[1]} isMulti onChange={(e) => this.setFilterItem(e)} placeholder="All" />
            </Col>
          </Row>
        );
      } else if (this.state.filterItem === "3") {
        row = (
          <Row className="align-items-center">
            <Col>Quality Dashboard</Col>
            <Col sm={3} md={3} lg={3}>
              <Select options={selectOptions} isMulti onChange={(e) => this.setFilterItem(e)} />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ toDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
          </Row>
        );
      }

      return (
        <div>
          <Card style={{ width: "100%" }}>
            <Card.Header>
              <Card.Title>{row}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="text-center">
                  <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("1", `${companyPrefix}% Per Category`)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Doughnut id="1" data={catPercentageData} height={400} plugins={[ChartDataLabels]} options={catPercentageOptions} />
                </Col>
                <Col className="text-center">
                  <Button
                    variant={"success"}
                    size="sm"
                    title="Download Chart"
                    onClick={() => this.saveChartToPng("2", `Average ${companyPrefix} Age Per Department`)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Bar id="2" data={carAgePerDepartmentData} height={400} plugins={[ChartDataLabels]} options={carAgePerDepartmentOptions} />
                </Col>
                <Col className="text-center">
                  <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("3", `Cost Per Department`)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Bar id="3" data={costPerDepartmentsData} height={400} plugins={[ChartDataLabels]} options={costPerDepartmentsOptions} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
